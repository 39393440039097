:root {
  // Typography colors
  --color-text: rgb(0 0 0 / 54%);
  --color-title: #383535;
  --color-subtitle: #3f444a;

  // external link
  --color-external-link: #4285f4;

  // background fill colors (e.g background)
  --color-background-light: #f7f8fa;
  --color-background-medium: #edeff3;
  --color-background-dark: #d2d6dc;

  // border colors
  --color-border-light: #f3f5f8;
  --color-border-medium: #e7eaef;
  --color-border-dark: #d2d6dc;

  // Primary color
  --color-primary-50: #ffefe0;
  --color-primary-100: #ffd7b3;
  --color-primary-200: #ffbc80;
  --color-primary-300: #ffa14d;
  --color-primary-400: #ff8d26;
  --color-primary-500: #ff7900;
  --color-primary-600: #ff7100;
  --color-primary-700: #f60;
  --color-primary-800: #ff5c00;
  --color-primary-900: #ff4900;
  --color-primary-a100: #fff;
  --color-primary-a200: #fff5f2;
  --color-primary-a400: #ffcebf;
  --color-primary-a700: #ffbaa6;

  // Primary Contrast
  --color-contrast-50: #000;
  --color-contrast-100: #000;
  --color-contrast-200: #000;
  --color-contrast-300: #000;
  --color-contrast-400: #fff;
  --color-contrast-500: #fff;
  --color-contrast-600: #fff;
  --color-contrast-700: #fff;
  --color-contrast-800: #fff;
  --color-contrast-900: #fff;
  --color-contrast-a100: #000;
  --color-contrast-a200: #000;
  --color-contrast-a400: #000;
  --color-contrast-a700: #000;

  // Accent color
  --color-accent-50: #f1f2f4;
  --color-accent-100: #dce0e3;
  --color-accent-200: #c4cbd0;
  --color-accent-300: #acb6bd;
  --color-accent-400: #9ba6ae;
  --color-accent-500: #8996a0;
  --color-accent-600: #818e98;
  --color-accent-700: #76838e;
  --color-accent-800: #6c7984;
  --color-accent-900: #596873;
  --color-accent-a100: #e0f1fe;
  --color-accent-a200: #8996a0;
  --color-accent-a400: #818e98;
  --color-accent-a700: #76838e;

  // Accent Contrast
  --color-accent-contrast-50: #000;
  --color-accent-contrast-100: #000;
  --color-accent-contrast-200: #000;
  --color-accent-contrast-300: #000;
  --color-accent-contrast-400: #000;
  --color-accent-contrast-500: #dbe1e6;
  --color-accent-contrast-600: #dbe1e6;
  --color-accent-contrast-700: #dbe1e6;
  --color-accent-contrast-800: #fff;
  --color-accent-contrast-900: #fff;
  --color-accent-contrast-a100: #000;
  --color-accent-contrast-a200: #dbe1e6;
  --color-accent-contrast-a400: #dbe1e6;
  --color-accent-contrast-a700: #dbe1e6;
}
