@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body,
html {
  height: 100%;
  font-size: var(--font-size-base);
}

a {
  @apply underline text-primary-500 cursor-pointer;
}

.page-container {
  width: 100%;
  max-width: 1300px;
  padding: 20px 12px;
  margin: auto;
}

.page {
  @apply overflow-y-auto;

  height: calc(100vh - var(--top-navigation-height));

  &:has(.floating-plan) {
    width: calc(100% - var(--floating-plan-width));
  }
}
