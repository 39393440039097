/* stylelint-disable selector-class-pattern */
mat-chip-grid.reduced-height {
  margin-top: -7px;
  margin-bottom: -10px;
}

.mat-mdc-tab-header {
  @apply border-b border-gray-200;
}

// https://github.com/tailwindlabs/tailwindcss/discussions/9993?sort=new
.mdc-notched-outline__notch {
  border-style: none;
}

.custom-mat-table {
  .mdc-data-table__header-cell {
    font-weight: 600;
    color: var(--color-text);
  }

  .mdc-data-table__cell {
    color: var(--color-text);
  }
}

// https://github.com/angular/components/issues/25981
// Progress bar not showing different b, it always use primary-500 for both
// bar and background, because it can't valculate from custom css variables
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #3f51b540 !important;
}

.mat-mdc-form-field-icon-prefix > em-icon,
.mat-mdc-form-field-icon-suffix > em-icon {
  padding: 12px;
  box-sizing: content-box;
  display: block;
}
