$google-btn-color: #444;

// Encourage proper use of role :)
*[role='button'] {
  cursor: pointer;
}

mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

// slide toggle override for status with custom colors
.mat-mdc-slide-toggle.em-status-toggle:not(.mat-disabled) {
  &:not(.mat-mdc-slide-toggle-checked) {
    .mdc-form-field {
      @apply text-danger-500  #{!important};
    }

    .mdc-switch__track {
      &::after,
      &::before {
        @apply bg-danger-400  #{!important};
      }
    }

    .mdc-switch__shadow {
      @apply bg-danger-700  #{!important};
    }
  }

  // checked state definitions
  &.mat-mdc-slide-toggle-checked {
    .mdc-form-field {
      @apply text-success-500;
    }

    .mdc-switch__track {
      &::after,
      &::before {
        @apply bg-success-500  #{!important};
      }
    }

    .mdc-switch__shadow {
      @apply bg-success-600  #{!important};
    }
  }
}

// fix for safari related input bug
input[type='search'] {
  appearance: textfield;

  &::-webkit-search-decoration {
    appearance: none;
  }
}

@media (max-width: 768px) {
  // modal dailogs should be start from top on Tablet Portrait and smaller resolutions
  .cdk-overlay-container .cdk-global-overlay-wrapper {
    align-items: flex-start !important; // scss-lint:disable ImportantRule
    margin-top: 25px;
  }
}

.chart-custom-tooltip {
  padding: 10px;
}

.mat-drawer-container {
  @apply bg-lightColor #{!important};
}

button.google-auth-btn,
.google-auth-btn button {
  font-family: Roboto, sans-serif !important;
  font-weight: 500;
  font-size: 14px;
  color: $google-btn-color !important;

  img {
    margin-right: 24px;
  }
}

.close-modal-btn {
  position: absolute !important;
  right: 0;
  top: 0;
}

// for new mdc-radio
.mat-mdc-radio-button.align-items-start {
  .mdc-form-field {
    align-items: flex-start;
  }

  .mdc-radio {
    margin-top: -8px;
  }
}

.ngx-bar {
  @apply bg-primary-500 #{!important};
}

// small form-field, used for repricing, maybe be remove in the future
.mat-mdc-form-field.form-field-sm {
  .mat-mdc-form-field-infix {
    min-height: 35px;
    padding-top: 8px !important;
    padding-bottom: 5px !important;
  }
}
